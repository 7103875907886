import React, { useEffect, useState, useTransition } from "react";
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addWeeks,
  addMonths,
  subWeeks,
  subMonths,
  eachDayOfInterval,
  parseISO,
  isSameDay,
} from "date-fns";
import "./Shifts.scss";
import axios from "axios";
import { BASE_API_URL } from "../../utils/consts";
import { useSelector } from "react-redux";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/arrowLeft.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as AddIcon } from "../../assets/images/icons/plusCircle.svg";
import AddShiftModal from "../../Components/Modals/AddShiftModal/AddShiftModal";
interface Shift {
  _id: string;
  userId: string;
  clientId: string;
  date: string;
  startTime: string;
  endTime: string;
  shiftType: string;
  durationInHours: number;
}

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  clientId: string;
  avatar: {
    url: string;
  };
  shifts: Shift[];
}

interface ShiftSchedulerProps {
  //   users: User[];
}

const ShiftScheduler: React.FC<ShiftSchedulerProps> = () => {
  const { t } = useTranslation();
  const [view, setView] = useState<"week" | "month">("week");
  const [currentDate, setCurrentDate] = useState(new Date());
  const userData = useSelector((state: any) => state.userData.user);
  const [users, setUsers] = useState<any>(undefined);
  const [shiftModalOpen, setShiftModalOpen] = useState<any>(undefined);
  const getFormattedDate = (date: Date) => {
    return format(date, "yyyy-MM-dd"); // Local time, 'YYYY-MM-DD'
  };

  // Calculate dynamic date range based on currentDate and view
  const fromDate = getFormattedDate(
    view === "week"
      ? startOfWeek(currentDate, { weekStartsOn: 1 }) // Week starts on Monday
      : startOfMonth(currentDate)
  );

  const toDate = getFormattedDate(
    view === "week"
      ? endOfWeek(currentDate, { weekStartsOn: 1 })
      : endOfMonth(currentDate)
  );
  useEffect(() => {
    if (userData.clientId) {
      axios
        .get(
          `${BASE_API_URL}/users-with-shifts/${userData.clientId}?from=${fromDate}&to=${toDate}`
        )
        .then((res) => {
          setUsers(res.data.data);
          // You can handle the users and shifts here if needed
        })
        .catch((err) => console.error(err));
    }
  }, [userData.clientId, currentDate, view]);

  const handlePrev = () => {
    setCurrentDate(
      view === "week" ? subWeeks(currentDate, 1) : subMonths(currentDate, 1)
    );
  };

  const handleNext = () => {
    setCurrentDate(
      view === "week" ? addWeeks(currentDate, 1) : addMonths(currentDate, 1)
    );
  };

  const getVisibleDates = () => {
    if (view === "week") {
      const start = startOfWeek(currentDate, { weekStartsOn: 1 }); // Monday
      const end = endOfWeek(currentDate, { weekStartsOn: 1 });
      return eachDayOfInterval({ start, end });
    } else {
      const start = startOfMonth(currentDate);
      const end = endOfMonth(currentDate);
      return eachDayOfInterval({ start, end });
    }
  };

  const visibleDates = getVisibleDates();

  console.log("users", users);
  console.log("visibleDates", visibleDates);
  return (
    <div className="shift-scheduler">
      {/* Header */}
      <div className="scheduler-header">
        <div className="period">
          <label>Period</label>
          <div className="selectDate">
            <button onClick={handlePrev}>
              <ArrowIcon />
            </button>
            <h2>
              {view === "week"
                ? `${format(
                    startOfWeek(currentDate, { weekStartsOn: 1 }),
                    "MMM d"
                  )} - ${format(
                    endOfWeek(currentDate, { weekStartsOn: 1 }),
                    "MMM d, yyyy"
                  )}`
                : `${format(currentDate, "MMMM yyyy")}`}
            </h2>
            <button onClick={handleNext} className="nextBtn">
              <ArrowIcon />
            </button>
          </div>
        </div>
        <div className="viewType">
          <label>{t("View Type")}</label>
          <div>
            <button
              onClick={() => setView("week")}
              className={`${view === "week" ? "active" : ""}`}
            >
              Week
            </button>
            <button
              onClick={() => setView("month")}
              className={`${view === "month" ? "active" : ""}`}
            >
              Month
            </button>
          </div>
        </div>
      </div>

      {/* Grid Layout */}
      <div className="scheduler-grid">
        {/* Header Row (Days) */}
        <div className="grid-row grid-header">
          <div className="grid-cell employee-header">Employee</div>
          <div className="dates-header">
            {visibleDates.map((date) => (
              <div key={date.toISOString()} className="grid-cell date-header">
                <div>{format(date, "EEE")[0]}o</div> {/* Mo, Tu, We, etc. */}
                <div>{format(date, "d")}</div> {/* 1, 2, 3, etc. */}
              </div>
            ))}
          </div>
        </div>

        {/* User Rows */}
        {users?.map((user: any) => {
          return (
            <div key={user._id} className="grid-row">
              <div className="grid-cell employee-name">
                {user.user.firstName} {user.user.lastName}
              </div>
              <div className="dates-row">
                {visibleDates.map((dateStr, index) => {
                  const date = new Date(dateStr); // Converting the string to Date object
                  // Find all shifts for the current user on the specific date
                  const shiftsForDate = user.shifts.filter((shift: any) => {
                    const shiftDate = new Date(shift.date); // Convert shift date to Date object
                    // Compare the two dates while ignoring the time part (just compare the day)
                    return isSameDay(date, shiftDate);
                  });

                  return (
                    <div key={index} className="grid-cell shift-data">
                      {shiftsForDate.length > 0
                        ? shiftsForDate.map((shift: any, i: number) => (
                            <div
                              className={`shift ${view}`}
                              key={i}
                              style={{
                                border: `2px solid ${
                                  user?.user?.color ?? "grey"
                                }`,
                                margin: 4,
                                backgroundColor: user?.user?.color
                                  ? `rgba(${parseInt(
                                      user?.user?.color.slice(1, 3),
                                      16
                                    )}, ${parseInt(
                                      user?.user?.color.slice(3, 5),
                                      16
                                    )}, ${parseInt(
                                      user?.user?.color.slice(5, 7),
                                      16
                                    )}, 0.5)` // Adjust opacity here
                                  : "rgba(128, 128, 128, 0.1)", // Default grey background with opacity
                              }}
                            >
                              {/* {format(new Date(shift.date), "dd-MM")}{" "} */}
                              {/* Date */}
                              {/* ${shift.shiftType} */}
                              {/* {user?.user?.firstName.charAt(0)}{" "}
                              {user?.user?.lastName.charAt(0)} */}
                              <div className="shiftDate">
                                {" "}
                                {` ${shift.startTime} `} <br />
                                {shift.endTime}
                              </div>
                              {/* Shift details */}
                            </div>
                          ))
                        : "-"}
                      <div
                        className="addShift"
                        onClick={() =>
                          setShiftModalOpen({
                            user: user.user,
                            date: dateStr,
                            shift: shiftsForDate,
                          })
                        }
                      >
                        <AddIcon />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {shiftModalOpen && (
        <AddShiftModal
          isOpen={shiftModalOpen}
          onCloseModal={() => setShiftModalOpen(undefined)}
          onAddShift={() => {}}
          shiftModalOpen={shiftModalOpen}
        />
      )}
    </div>
  );
};

export default ShiftScheduler;
