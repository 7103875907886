import axios from "axios";
import React, { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import Table from "antd/es/table";
import { BASE_API_URL } from "../../utils/consts";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "../Payments/Payments.scss";

type Props = {};

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}
const Payments: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [payments, setPayments] = useState<any>(undefined);
  const userData = useSelector((state: any) => state.userData.user);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });
  const [selectedMonth, setSelectedMonth] = useState<any>(dayjs(new Date()));

  useEffect(() => {
    if (userData.clientId) {
      const startDate = dayjs(selectedMonth).startOf('month').format('YYYY-MM-DD');
      const endDate = dayjs(selectedMonth).endOf('month').format('YYYY-MM-DD');

      axios
        .get(`${BASE_API_URL}/${userData.clientId}/patientsDetails`, {
          params: {
            startDate, endDate
          }
        })
        .then((res) => {
          const notesArrays = res.data.patientDetails.map((obj: any) => obj.notes);

          // Use reduce to flatten the array of arrays into a single array
          const allNotes = notesArrays.reduce(
            (acc: any, notes: any) => acc.concat(notes),
            []
          );

          setPayments(allNotes);
          setPagination({
            ...pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
            pageSize: res.data.pageSize
          });
        })
        .catch((error) => console.error(error));
    }
  }, [userData, selectedMonth]);

  const calculateCurrency = () => {
    if (userData.client.currency === "Dinar") {
      return "RSD";
    } else if (userData.client.currency === "Euro") {
      return "EUR";
    } else {
      return "$";
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("Costs"),
      dataIndex: "costs",
      key: "costs",
      render: (text) => (
        <>
          {text} {calculateCurrency()}
        </>
      ),
    },
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
      width: 120,
      render: (value) => format(new Date(value), "d-MMM-yyyy"),
    },
    {
      title: t("Title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("Description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("Doctor"),
      dataIndex: "userId",
      key: "userId",
      render: (row) => {
        return row?.username;
      },
    },
  ];

  const handlePrevMonth = () => {
    setSelectedMonth(selectedMonth.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setSelectedMonth(selectedMonth.add(1, "month"));
  };

  return (
    <div className="mainContainer">
      <div className="homeDatePicker">
        <div className="prev" onClick={handlePrevMonth}>
          <LeftOutlined />
        </div>
        <div className="date">
          <DatePicker
            picker="month"
            allowClear={false}
            value={selectedMonth}
            //   disabledDate={disabledDate}
            onChange={(date: any, dateString: any) => {
              setSelectedMonth(dayjs(dateString));
            }}
          />
        </div>
        <div className="next" onClick={handleNextMonth}>
          <RightOutlined />
        </div>
      </div>
      <Table columns={columns} dataSource={payments} pagination={{
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
        onChange: (page) => setPagination({
          current: page,
          pageSize: 10,
          total: pagination.total
        }),
        showSizeChanger: false, // Sakriva dropdown za izbor broja redova
      }} />
    </div>
  );
};

export default Payments;
