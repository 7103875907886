import React, { useEffect, useState } from "react";
import { DatePicker, Modal, Select, TimePicker } from "antd";
import { Button, Form, InputNumber } from "antd";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { format, getHours, getMinutes } from "date-fns";
import { useTranslation } from "react-i18next";
import { ReactComponent as RepeatIcon } from "../../../assets/images/icons/repeat.svg";
import "./AddShiftModal.scss";
const { Option } = Select;

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  onAddShift: (costs: any) => void;
  shiftModalOpen: any;
};

const AddShiftModal: React.FC<Props> = ({
  isOpen,
  onCloseModal,
  onAddShift,
  shiftModalOpen,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using Form.useForm()

  console.log("shiftModalOpen", shiftModalOpen);
  useEffect(() => {
    // Set form initial values when activeNotes changes

    // Reset form fields if activeNotes is null or undefined
    form.resetFields();
  }, [form]);
  const handleOk = () => {
    onCloseModal();
  };
  const handleCancel = () => {
    onCloseModal();
  };

  const onFinish = async (values: any) => {
    onAddShift(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <Modal
      title={
        <div className="addShiftHeader">
          <div className="col">
            <div className="profileImg">
              {shiftModalOpen?.user?.avatar?.url ? (
                <img
                  src={shiftModalOpen?.user?.avatar?.url ?? ""}
                  alt="Profile"
                />
              ) : (
                <div className="initials">
                  {`${shiftModalOpen?.user?.firstName?.[0] || ""}${
                    shiftModalOpen?.user?.lastName?.[0] || ""
                  }`}
                </div>
              )}
            </div>
            <div className="info">
              <div className="name">
                {shiftModalOpen?.user?.firstName}{" "}
                {shiftModalOpen?.user?.lastName}
              </div>
              <div className="date">
                {format(shiftModalOpen?.date, "MM.dd.yyyy")}
              </div>
            </div>
          </div>
          <div className="col repeat">
            <RepeatIcon />
          </div>
        </div>
      }
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        //   initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={t("Costs")}
          name="costs"
          rules={[{ required: true, message: t("Please enter costs") }]}
        >
          <InputNumber min={1} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddShiftModal;
