import React, { useEffect, useState, useTransition } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { setLoader } from "../../utils/redux/slices/loaderSlice";
import axios from "axios";
import { BASE_API_URL } from "../../utils/consts";
import { useDispatch } from "react-redux";
import "./Calendars.scss";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../utils/useAuth";
// const events = [
//   { title: "Meeting", start: new Date(), end: addDays(new Date(), 4) },
//   { title: "Meeting 2", start: new Date(), end: addDays(new Date(), 2) },
//   { title: "Meeting", start: new Date(), end: addDays(new Date(), 4) },
//   { title: "Meeting 2", start: new Date(), end: addDays(new Date(), 2) },
//   { title: "Meeting", start: new Date(), end: addDays(new Date(), 4) },
//   { title: "Meeting 2", start: new Date(), end: addDays(new Date(), 2) },
//   { title: "Meeting", start: new Date(), end: addDays(new Date(), 4) },
//   { title: "Meeting 2", start: new Date(), end: addDays(new Date(), 2) },
// ];
type Props = {
  selectedLeave?: any;
  selectedLeaveYear?: any;
};

const AllCalendar: React.FC<Props> = ({ selectedLeave, selectedLeaveYear }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = localStorage.getItem("user") ?? undefined;
  const [events, setEvents] = useState<any>(undefined);
  const clientId = user && JSON.parse(user)?.clientId;
  const getLanguage = localStorage.getItem("userLanguage");
  useEffect(() => {
    dispatch(setLoader(true));
    axios
      .get(`${BASE_API_URL}/${clientId}/leaves?leaveName=["${selectedLeave}"]`)
      .then((res) => {
        const allEvents = res.data?.map((item: any) => {
          return {
            title: `${item.userId.firstName} ${item.userId.lastName} - ${item.description}`,
            start: item.from,
            end: item.to,
            color: setBackgroundColorWithOpacity(item?.userId?.color, "0.6"),
            className: "eventsChild",
          };
        });
        setEvents(allEvents);
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoader(false)));
  }, [selectedLeave, selectedLeaveYear, clientId, dispatch]);

  function setBackgroundColorWithOpacity(hexColor: any, opacity: any) {
    // Convert hex to RGB
    var hex = hexColor.substring(1); // Remove # symbol
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);

    // Set RGBA color
    return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
  }

  const customSerbianLatinLocale = {
    code: "sr-latn",
    buttonText: {
      today: t("Today"),
      month: t("Month"),
      week: t("Week"),
      day: t("Day"),
      list: t("List"),
    },
    // ... other translations
  };

  //   const customSerbianLatinLocale = {
  //     code: "sr-latn",
  //     buttonText: {
  //       today: t("Today"),
  //       month: t("Month"),
  //       week: t("Week"),
  //       day: t("Day"),
  //       list: t("List"),
  //     },
  //     // ... other translations
  //   };
  function renderEventContent(eventInfo: any) {
    return (
      <div>
        <b>
          {/* {format(new Date(eventInfo.event._instance?.range?.star), "HH-mm")}{" "} */}
          {/* - {new Date(eventInfo.event._instance?.range?.end)} */}
        </b>
        <p>{eventInfo.event.title}</p>
      </div>
    );
  }

  return (
    <div className="">
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        locales={[customSerbianLatinLocale]}
        locale={getLanguage === "en" ? "en" : "sr-latn"}
        weekends={true}
        height={650}
        selectable={true}
        dayMaxEvents={5}
        expandRows={true}
        eventClick={(data) => console.log("click", data)}
        events={events ?? []}
        eventContent={renderEventContent}
        customButtons={{
          myCustomButton: {
            text: "custom!",
            click: function () {
              alert("clicked the custom button!");
            },
          },
        }}
      />
    </div>
  );
};

export default AllCalendar;
