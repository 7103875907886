import { Button, Col, Row, Space, Tabs, TabsProps, Tag } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { BASE_API_URL } from "../../utils/consts";
import axios from "axios";
import Table, { ColumnsType } from "antd/es/table";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  CloseOutlined,
  CheckOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { isAdmin } from "../../utils/permissions";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../utils/redux/slices/loaderSlice";
import AllCalendar from "../Calendars/Calendars";
import { Select } from "antd";
import { setClientLeaves } from "../../utils/redux/slices/clientLeaves";

import "./Leaves.scss";
type Props = {};

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const Leaves: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = localStorage.getItem("user") ?? undefined;
  const userDetails = user && JSON.parse(user);
  const clientId = user && JSON.parse(user)?.clientId;
  const [leaves, setLeaves] = useState<any>(undefined);
  const [selectedLeave, setSelectedLeave] = useState<any>(undefined);
  const [selectedLeaveYear, setSelectedLeaveYear] = useState<any>("2025");
  const clientLeaves = useSelector(
    (state: any) => state.clientLeaves.clientLeaves
  );
  useEffect(() => {
    if (selectedLeave) {
      dispatch(setLoader(true));
      axios
        .get(
          `${BASE_API_URL}/${clientId}/leaves?leaveName=["${selectedLeave}"]&year=${selectedLeaveYear}`
        )
        .then((res) => {
          setLeaves(res.data);
        })
        .catch((err) => console.error(err))
        .finally(() => dispatch(setLoader(false)));
    }
  }, [selectedLeave, selectedLeaveYear, clientId, dispatch]);

  useEffect(() => {
    if (clientId) {
      axios.get(`${BASE_API_URL}/admin/${clientId}/leaves`).then((res) => {
        dispatch(setClientLeaves(res.data));
      });
    }
  }, [clientId, dispatch]);

  useEffect(() => {
    if (clientLeaves?.length > 0) {
      setSelectedLeave(clientLeaves[0]._id);
    }
  }, [clientLeaves]);

  console.log("clientLeaves", clientLeaves);
  const onUpdateLeave = (status: any, leaveId: any) => {
    dispatch(setLoader(true));
    axios
      .put(`${BASE_API_URL}/${leaveId}/leave_update`, {
        isApproved: status,
      })
      .then((res) => {
        axios
          .get(`${BASE_API_URL}/${clientId}/leaves`)
          .then((res) => {
            setLeaves(res.data);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoader(false)));
  };

  const onDeleteLeave = (leaveId: any) => {
    dispatch(setLoader(true));
    axios
      .delete(`${BASE_API_URL}/${leaveId}/leave_delete`)
      .then((res) => {
        axios
          .get(`${BASE_API_URL}/${clientId}/leaves`)
          .then((res) => {
            setLeaves(res.data);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoader(false)));
  };
  const columns: ColumnsType<DataType> = [
    {
      title: t("Name"),
      dataIndex: "userId",
      key: "name",
      render: (row) => row?.username,
    },
    {
      title: t("Date Start"),
      dataIndex: "from",
      key: "from",
      render: (value, row) => {
        return format(new Date(value), "d-MMM-yyyy");
      },
    },
    {
      title: t("Date End"),
      dataIndex: "to",
      key: "to",
      render: (value, row) => {
        return format(new Date(value), "d-MMM-yyyy");
      },
    },
    {
      title: t("Leave Type"),
      dataIndex: "clientLeaveType",
      key: "clientLeaveType",
    },
    {
      title: t("Status"),
      dataIndex: "isApproved",
      key: "isApproved",
      render: (value, status) => {
        const color =
          value === "APPROVED"
            ? "green"
            : value === "PENDING"
            ? "orange"
            : "red";
        return (
          <>
            <Tag color={color} key={value}>
              {t(value)}
            </Tag>
          </>
        );
      },
    },

    {
      title: "",
      key: "action",
      render: (row, record: any) => {
        return (
          <>
            {isAdmin(userDetails?.roles) && (
              <Space size="middle">
                <Button onClick={() => onUpdateLeave("APPROVED", record._id)}>
                  <CheckOutlined />
                </Button>
                <Button onClick={() => onUpdateLeave("CANCEL", record._id)}>
                  <CloseOutlined />
                </Button>
                <Button onClick={() => onDeleteLeave(record._id)}>
                  <DeleteOutlined />
                </Button>
              </Space>
            )}
          </>
        );
      },
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: t("List"),
      children: (
        <Row>
          <Col xs={24}>
            <Table columns={columns} dataSource={leaves} />
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: t("Calendar"),
      children: (
        <Row>
          <Col xs={24}>
            <AllCalendar
              selectedLeave={selectedLeave}
              selectedLeaveYear={selectedLeaveYear}
            />
          </Col>
        </Row>
      ),
    },
  ];

  const onChangeLeaveType = (value: string) => {
    console.log(`selected ${value}`);
    setSelectedLeave(value);
  };

  const onChangeLeaveYear = (value: string) => {
    console.log(`selected ${value}`);
    setSelectedLeaveYear(value);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  return (
    <div className="mainContainer">
      <div className="globalFilters">
        <div className="filter">
          <label htmlFor="leaveTypeDropdown" className="dropdownLabel">
            {t("Leave Type")}
          </label>
          <Select
            id="leaveTypeDropdown"
            style={{ minWidth: 200 }}
            showSearch
            placeholder={t("Select a leave type")}
            optionFilterProp="label"
            onChange={onChangeLeaveType}
            onSearch={onSearch}
            value={selectedLeave}
            options={clientLeaves?.map((leave: any) => ({
              value: leave._id,
              label: leave.leaveName,
            }))}
          />
        </div>
        <div className="filter">
          <label htmlFor="leaveTypeDropdown" className="dropdownLabel">
            {t("Year")}
          </label>
          <Select
            id="leaveTypeDropdown"
            style={{ minWidth: 200 }}
            showSearch
            placeholder={t("Select a year")}
            optionFilterProp="label"
            onChange={onChangeLeaveYear}
            // onSearch={onSearch}
            value={selectedLeaveYear}
            options={[
              { value: "2025", label: "2025" },
              { value: "2024", label: "2024" },
              { value: "2023", label: "2023" },
            ]}
          />
        </div>
      </div>
      <Tabs defaultActiveKey="1" items={tabItems} onChange={onChange} />
    </div>
  );
};

export default Leaves;
